import { withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import { navigate } from "gatsby";
import { useContext, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import axiosClient from "../../../api/axiosClient";
import { getMeApi, preLoginApi } from "../../../api/users.ts";
import { INITIAL_APP_STATE } from "../../../commons/enum";
import CustomButton, {
  CustomButtonOutLine,
} from "../../../components/custom-button";
import CheckUserLoggedInContext from "../../../context/check-user-logged-in-context";
import DataLoadingContext from "../../../context/data-loading-context";
import {
  useCompanyInfo,
  useQueryInvalidationWithNotification,
} from "../../../hooks";
import { useIsLoggedIn } from "../../../hooks/user.hooks.ts";
import { primaryColor } from "../../../scss/colors.scss";
import { updateUserLastLoginTime } from "../../../utils/auth";
import { checkEmailVerification } from "../../../utils/email-verification";
import { cognitoLogin } from "../../../utils/user-auth-provider";

const CssTextField = withStyles({
  root: {
    width: "100%",
    display: "flex",
    marginBottom: "2rem",
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: primaryColor,
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  },
})(TextField);

const LoginForm = () => {
  const { data: companyInfo } = useCompanyInfo();
  const { setCheckUserLoggedIn } = useContext(CheckUserLoggedInContext);
  const { setAppState } = useContext(DataLoadingContext);
  const isLoggedIn = useIsLoggedIn();
  const redirect =
    new URLSearchParams(window.location.search).get("redirect") || "/";
  useEffect(() => {
    if (!isLoggedIn) {
      setAppState(INITIAL_APP_STATE);
    }
  }, [isLoggedIn]);
  const queryClient = useQueryClient();
  const invalidateQueries = useQueryInvalidationWithNotification();
  const formRef = useRef("");
  const [gettingLoginStatus, setGettingLoginStatus] = useState(false);

  const handleLogin = () => {
    formRef.current.handleSubmit();
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };
  const [emailError, setEmailError] = useState("");
  return (
    <Formik
      innerRef={formRef}
      initialValues={{ email: "", password: "" }}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          setEmailError("Required");
        } else if (checkEmailVerification(values.email)) {
          setEmailError("Invalid email address");
        } else {
          setEmailError("");
        }
        if (!values.password) {
          errors.password = "Required";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        const params = {
          email: values.email,
          password: values.password,
          companyId: companyInfo?.id || "",
        };
        setGettingLoginStatus(true);
        const response = preLoginApi(params);
        response
          .then(() => {
            return cognitoLogin(params.email, params.password);
          })
          .then((cognitoUser) => {
            setCheckUserLoggedIn(true);
            axiosClient.defaults.headers.common["authentication"] =
              cognitoUser.signInUserSession.accessToken.jwtToken;
            invalidateQueries("isLoggedIn");
            return getMeApi();
          })
          .then((userInfor) => {
            queryClient.setQueryData("myInfo", userInfor);
            updateUserLastLoginTime();
            navigate(redirect);
          })
          .catch((err) => {
            let errorMessage = "Something was wrong. Please try again";
            if (err?.message) errorMessage = err?.message;
            if (err?.data?.message) errorMessage = err?.data?.message;
            setEmailError(errorMessage);
          })
          .finally(() => {
            setGettingLoginStatus(false);
          });
        setSubmitting(false);
      }}
    >
      {({ values, touched, handleChange, handleSubmit }) => (
        <form className="login-form" onSubmit={handleSubmit}>
          <CssTextField
            label="Email"
            type="email"
            name="email"
            onChange={handleChange}
            value={values.email}
            variant="outlined"
            id="custom-css-outlined-input"
            error={emailError && touched.email}
          />

          <CssTextField
            id="outlined-password-input"
            label="Password"
            type="password"
            name="password"
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            value={values.password}
            autoComplete="current-password"
            variant="outlined"
            helperText={emailError && touched.email && emailError}
            error={!!emailError && touched.email}
          />
          <div
            className="forgot-password"
            onClick={() => navigate("/forgot-password")}
            role="button"
            tabIndex="0"
            onKeyPress={() => {}}
          >
            <span>Forgot Password?</span>
          </div>

          <div className="submit-button-group">
            <CustomButton
              isLoading={gettingLoginStatus}
              label="Login"
              onKeyPress={() => {}}
              onClick={() => {
                handleLogin();
              }}
            />
            <CustomButtonOutLine
              label="Signup"
              onKeyPress={() => {}}
              onClick={() =>
                navigate(
                  `/signup?redirect=${encodeURIComponent(
                    `/accept-invitation${window.location.search}`
                  )}`
                )
              }
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
